import React, {useEffect} from "react";
import CodesWidget from "../components/codes/codes-widget";
import {goToAnchor} from "../helpers/go-to-anchor";
import {findGetParameter} from "../helpers/find-get-param";
import Reg from "../components/modals/Reg";
import ChangePass from "../components/modals/ChangePass";
import {useModal} from "../context";

function End() {
    const { show, hide} = useModal();

    useEffect(() => {
        let urlParams = window.location.search.substr(1);
        if(urlParams !== '' && urlParams !== undefined && urlParams !== null){
            let result = findGetParameter('return') ?? findGetParameter('result');
            let auth_key = findGetParameter('auth_key');

            if(auth_key !== null){
                localStorage.setItem('auth_key', auth_key);
                if(parseInt(result) === 1){
                    window.location.href = process.env.PUBLIC_URL + '/cabinet';
                }

                if(parseInt(result) === 2){
                    show(<ChangePass/>, "modal--form modal--pass");
                    window.history.pushState({param: 'Value'}, '', '/');
                }
            }
        }

        if(window.location.hash){
            goToAnchor(window.location.hash.substring(1));
            window.history.pushState({param: 'Value'}, '', '/');
        }
    },[]);

    return (
        <div className="content">
            <div id="promo" className="sc__promo sc__promo--end">
                {window.matchMedia("(min-width: 1024px)").matches && (
                    <div className="promo__anim desk-only">
                        <div className="anim__box">
                            <iframe src="/animation/promo/index.html" frameBorder="0"></iframe>
                        </div>
                    </div>
                )}

                {window.matchMedia("(max-width: 1023px)").matches && (
                    <div className="promo__anim mb-only">
                        <div className="anim__box">
                            <iframe src="/animation/promo/mobile/index.html" frameBorder="0"></iframe>
                        </div>
                    </div>
                )}
                <div className="promo__bg">
                    <span className="star__light-decor mb-only"></span>
                    <span className="star__light-decor star__light-decor--2 mb-only"></span>
                    <span className="star__light-decor star__light-decor--3 mb-only"></span>
                </div>
                <div className="container d-flex align-items-center">
                    <div className="promo__ct promo__ct--end">

                        <div className="star__decor star__decor--promo"/>
                        <div className="end__title gradient-text2">
                            <span>Акция завершилась.</span> <br/>
                            Призовой фонд будет доставлен победителям до <br className="mb-only"/>28 февраля 2025 года
                        </div>
                        <div className="end__subtitle">
                            Остались вопросы? Напишите нам: <a href="mailto:korkunov@tma-draft.com">korkunov@tma-draft.com</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default End;
