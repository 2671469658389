import { Link, useLocation  } from 'react-router-dom'
import React, {useEffect, useState} from "react";
import {useProfile, useModal, useEnd} from "../context";
import classNames from "classnames";
import Login from "./modals/Login";
import ChangePass from "./modals/ChangePass";
import CodesWidget from "./codes/codes-widget";
import Prize from "./modals/Prize";
import Info from "./modals/Info";
import {goToAnchor} from "../helpers/go-to-anchor";

function Header() {
    const [nav, setNav] = useState(false);
    const [fixed, setFixed] = useState(false);
    const [scrolledScreen, setScrolledScreen] = useState(false);
    const [lkNav, setLkNav] = useState(false);
    const location = useLocation();
    const {end} = useEnd();
    const {profile} =  useProfile();

    const { show, hide} = useModal();
    const [inner, setInner] = useState(false);

    useEffect(()=>{

    }, []);

    useEffect(() => {
        setInner(location.pathname != '/');
        window.scroll(0, 0);
        if(location.pathname != '/'){
            document.getElementById('root').removeAttribute("style");
            document.getElementById('wrapper').removeAttribute("style");
        }

        setNav(false);
        setLkNav(false);
        document.body.classList.remove('overflow');
    }, [location.pathname]);

    useEffect(()=>{
        if(!profile.isLoading && Object.keys(profile.data).length > 0){
            /*let exist = false;
            Object.keys(profile.data.prizes).map((it, i) => {
                if(profile.data.prizes[it].push === 'no' && !exist){
                    show(<Prize prize={profile.data.prizes[it]} prize_id={it}/>, "modal--prize");
                    exist = true;
                }
            });*/
        }
    }, [profile]);

    useEffect(() => {
        const handleScroll = event => {
            if(window.scrollY > 5){
                setFixed(true);
            }else{
                setFixed(false);
            }

            if(window.scrollY > window.innerHeight){
                document.body.classList.add('scrolled--screen');
            }else{
                document.body.classList.remove('scrolled--screen');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const goToAnchor = (anchor) => {
        const el = document.getElementById(anchor);
        if(el){
            el.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
        }
        setNav(false);
        setLkNav(false);
        document.body.classList.remove('overflow');
    }

    return (
        <div id="header"
             className={
                 classNames('header fadeIn animated', (inner ? ' header--inner' : ''),
                     (nav ? ' active header--open' : ''),
                     (fixed ? ' header--fixed' : ''),
                     (!profile.loading ? ' header--loaded' : '')
                 )}>
            <div className="container d-flex align-items-center justify-content-between">
                <div className="header__left d-flex align-items-center">
                    {!inner && (
                        <div onClick={()=>{
                            goToAnchor('promo');
                        }} className="logo">
                            <img src={require("../assets/img/logo.png")}/>
                        </div>
                    )}
                    {inner && (
                        <Link to="/" className="logo">
                            <img src={require("../assets/img/logo.png")}/>
                        </Link>
                    )}
                </div>
                <div className="nav__box fadeIn animated">
                    <div className="nav__bl">
                        <div className="nav__menu">
                            <ul>
                                {/*<li>
                                    <Link to="/#steps" onClick={(e)=>{
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('steps');
                                        }
                                    }}>
                                        как Выиграть <br/>
                                        призы
                                    </Link>
                                </li>*/}
                                <li>
                                    <Link to="/winners">
                                        победители
                                    </Link>
                                </li>
                                {/*<li>
                                    <Link to="/#create" onClick={(e)=>{
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('create');
                                        }
                                    }}>
                                        Создайте карту <br/>
                                        желаний
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/#const" onClick={(e)=>{
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('const');
                                        }
                                    }}>
                                        Создайте новогоднее <br/>
                                        поздравление
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/#products" onClick={(e)=>{
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('products');
                                        }
                                    }}>
                                        Новогоднее настроение <br/>
                                        с продуктами КОРКУНОВ®
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/#buy" onClick={(e)=>{
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('buy');
                                        }
                                    }}>Где купить?</Link>
                                </li>
                                <li>
                                    <li>
                                        <Link to="/#faq" onClick={(e)=>{
                                            if(!inner) {
                                                e.preventDefault();
                                                goToAnchor('faq');
                                            }
                                        }}>
                                            Вопросы <br className="desk-only"/>
                                            и ответы
                                        </Link>
                                    </li>
                                </li>*/}
                            </ul>
                        </div>
                        {!profile.loading && (
                            <div className="nav__bottom mb-only">
                                <div className="nav__bottom-header">
                                    <div className="star__decor star__decor--nav"/>

                                    {Object.keys(profile.data).length === 0 && (
                                        <div onClick={()=>{
                                            setNav(false);
                                            setLkNav(false);
                                            document.body.classList.remove('overflow');

                                            show(<Login/>, "modal--form modal--login");
                                        }} className="btn__promo d-flex align-items-center justify-content-between">
                                            <span>Личный кабинет</span>
                                            <div className="star__decor star__decor--nav"/>
                                        </div>
                                    )}

                                    {Object.keys(profile.data).length > 0 && (
                                        <div className={'btn__lk' + (lkNav ? ' active' : '')}>
                                            <Link to="/cabinet" className="btn__promo d-flex align-items-center justify-content-between">
                                                <span className="text-nowrap">{profile.data.profile.email}</span>
                                                <div className="star__decor star__decor--nav"/>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                                {Object.keys(profile.data).length > 0 && (
                                    <div className="nav__lk-submenu">
                                        <div onClick={()=>{
                                            show(<ChangePass/>, "modal--form modal--passr");
                                        }} className="btn__lk-dropdown-it d-flex align-items-center justify-content-center">
                                            <span>
                                               Сменить пароль
                                           </span>
                                        </div>
                                        <a href={'https://t.me/KKV_tgbot?start=' + (profile.data.profile.userUuid ?? 'NEW')} target="_blank" className="btn__lk-dropdown-it d-flex align-items-center justify-content-center">
                                            <span>
                                               Связать кабинет с&nbsp;чат-ботом акции
                                           </span>
                                        </a>

                                        <div onClick={()=>{
                                            localStorage.removeItem('auth_key');
                                            window.location.reload();
                                        }} className="btn__lk-dropdown-it d-flex align-items-center justify-content-center">
                                            <span>
                                               Выход
                                           </span>
                                        </div>
                                    </div>
                                )}

                                {/*<div className="promo__btn-group d-flex align-items-center justify-content-between">
                                    <CodesWidget
                                        title="Зарегистрировать чек"
                                        classesList="btn__promo d-flex align-items-center justify-content-center"
                                    />
                                    <a href={'https://t.me/KKV_tgbot' + ((!profile.isLoading && Object.keys(profile.data).length > 0) ? '?start=' + profile.data.profile.userUuid : '')} target="_blank" className="promo__btn-tg">
                                        <img src={require("../assets/img/tg.svg").default}/>
                                    </a>
                                </div>
                                <div className="promo__trigger-box d-flex align-items-center justify-content-between">
                                    <div className="promo__trigger-title">
                                        Пара нажатий — и ваша новогодняя карта желаний готова. Попробуйте!
                                    </div>
                                    <Link to="/#create" onClick={(e)=>{
                                        if(!inner) {
                                            e.preventDefault();
                                            goToAnchor('create');
                                        }
                                    }} className="promo__trigger-btn d-flex align-items-center justify-content-center">
                                        Создать
                                    </Link>
                                </div>*/}
                            </div>
                        )}

                    </div>
                </div>
                <div className="header__right d-flex desk-only">
                    {!profile.loading && (
                        <div className="header__right-it">
                            {Object.keys(profile.data).length === 0 && (
                                <div onClick={()=>{
                                    show(<Login/>, "modal--form modal--login");
                                }} className="btn-ct text-nowrap btn-ct--small d-flex align-items-center justify-content-center">
                                    Личный кабинет
                                </div>
                            )}

                            {Object.keys(profile.data).length > 0 && (
                                <div className={'btn__lk' + (lkNav ? ' active' : '')}>
                                    <Link to="/cabinet" className="btn-ct text-nowrap btn-ct--small d-flex align-items-center justify-content-center">
                                        <span className="text-nowrap">Личный кабинет</span>
                                        <i className="btn__arrow">
                                            <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.5 1L5.5 5L9.5 1" stroke="#8B0340" strokeWidth="1.5"/>
                                            </svg>
                                        </i>
                                    </Link>
                                    <div className="btn__lk-dropdown">
                                        <div onClick={()=>{
                                            show(<ChangePass/>, "modal--form modal--passr");
                                        }} className="btn__lk-dropdown-it d-flex align-items-center">
                                            <span>
                                               Сменить пароль
                                           </span>
                                        </div>
                                        <a href={'https://t.me/KKV_tgbot?start=' + (profile.data.profile.userUuid ?? 'NEW')} target="_blank" className="btn__lk-dropdown-it d-flex align-items-center">
                                            <span>
                                               Связать кабинет <br/>
                                                с чат-ботом акции
                                           </span>
                                        </a>

                                        <div onClick={()=>{
                                            localStorage.removeItem('auth_key');
                                            window.location.reload();
                                        }} className="btn__lk-dropdown-it d-flex align-items-center">
                                            <span>
                                               Выход
                                           </span>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    )}

                </div>
                <div onClick={()=>{
                    setNav(!nav);
                    if(document.body.classList.contains('overflow')) {
                        document.body.classList.remove('overflow');
                    }else {
                        document.body.classList.add('overflow');
                    }
                }} className="nav__toggle d-flex align-items-center justify-content-center mb-only">
                    {!nav && (
                        <i className="icon__bars fadeIn animated">
                            <svg width="32" height="23" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M30.0306 1.97217H1.53271" stroke="#FFF7F4" strokeWidth="2.1028" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M29.7838 11.4678H10.7852" stroke="#FFF7F4" strokeWidth="2.1028" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M30.1308 21.3179L1.53271 21.3179" stroke="#FFF7F4" strokeWidth="2.1028" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </i>
                    )}

                    {nav && (
                        <i className="icon__close fadeIn animated">
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.9085 21.5521L1.75439 1.39795" stroke="#FFF7F4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21.9936 1.37998L1.76855 21.605" stroke="#FFF7F4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </i>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Header;
